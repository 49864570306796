import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0)
  }
}));

function ArticleBody(props) {
  const classes = useStyles();
  return (
    <Box width="100%" className={classes.root}>
      {props.children}
    </Box>
  )
}

export default ArticleBody