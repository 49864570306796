import React, { Fragment } from "react"
import { graphql, StaticQuery } from "gatsby"
import EmailIcon from '@material-ui/icons/MailOutline'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import ArticleBodyWrapper from 'components/ArticleBodyWrapper'
import CardItem from 'components/CardItem'
import CardList from 'components/CardList'
import CardSection from 'components/CardSection'
import Header from 'components/Header'
import Seo from "components/Seo"
import Layout from "components/Layout"
import ArticleBody from 'components/ArticleBody'

const buttons = [
  {
    text: 'Enquire about our Services',
    to: '/contact',
    icon: <EmailIcon />
  },
  {
    text: 'Return to home',
    to: '/',
    icon: <HomeIcon />
  },
]

export default () => (
  <Fragment>
    <StaticQuery
      query={graphql`
        query {
          services: markdownRemark(frontmatter: { name: { eq: "services" } }) {
            html
            frontmatter {
              title
              name
            }
          }
          site {
          siteMetadata {
            homepageHeader
            title
          }
        }
        allMarkdownRemark(
            sort: { order: ASC, fields: frontmatter___order }
            filter: { frontmatter: { type: { eq: "service" } } }
          ) {
            edges {
              node {
                frontmatter {
                  title
                  slug
                  metaDescription
                  tags
                  order
                  postImage {
                    childImageSharp {
                      fluid(maxWidth: 450) {
                        ...GatsbyImageSharpFluid
                      }
                  }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <Seo
            title={data.services.frontmatter.name}
            description={data.services.frontmatter.title}
          />
          <Header
            title={data.services.frontmatter.name}
            subtitle={data.services.frontmatter.title}
          />
          <CardSection
            buttons={buttons}
            scheme2
          >
            <ArticleBodyWrapper>
              <ArticleBody key={data.services.frontmatter.name} content={data.services.html} />
            </ArticleBodyWrapper>
            <CardList>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <CardItem
                  scheme2
                  key={node.frontmatter.title}
                  fluid={node.frontmatter.postImage.childImageSharp.fluid}
                  title={node.frontmatter.title}
                  description={node.frontmatter.metaDescription}
                  tags={node.frontmatter.tags}
                  slug={node.frontmatter.slug}
                  buttonText={'Learn More'}
                />
              ))
              }
            </CardList>
          </CardSection>
        </Layout>
      )}
    />
  </Fragment>
);
